<template lang="html">
  <div v-if="loading" class="spinner"></div>

  <div v-else class="CommunityStatsPage">
    <div class="CommunityStatsPage--GeneralInfo">
      <div class="CommunityStatsPage--GeneralInfoBox">
        <p>{{stats.userNum}}</p>
        <p>{{$t('users')}}</p>
      </div>
      <div class="CommunityStatsPage--GeneralInfoBox">
        <p>{{stats.commentsNum}}</p>
        <p>{{$t('comments')}}</p>
      </div>
      <div class="CommunityStatsPage--GeneralInfoBox">
        <p>{{stats.activitiesNum}}</p>
        <p>{{$t('activities')}}</p>
      </div>
    </div>
    <div class="CommunityStatsPage--MoreInfo">
      <div class="CommunityStatsPage--Sidebar">
        <h1>{{$t('tags')}}</h1>
        <div class="CommunityStatsPage--SidebarList">
          <div class="CommunityStatsPage--SidebarElement" v-for="(num,tag) in stats.userNumPerTag">
            <p>{{tag}}</p>
            <p><span>{{num}}</span> {{$t('users')}}</p>
          </div>
        </div>
      </div>
      <div class="CommunityStatsPage--Activities">
        <h1>{{$t('activities')}}</h1>
        <div class="CommunityStatsPage--ActivityList">
          <div class="CommunityStatsPage--Activity" v-for="(activity,i) in stats.activitiesUsersInfo">
            <div class="CommunityStatsPage--ActivityContent">
              <div>
                <img v-if="activity.activityType == 'WAT_SURVEY' || activity.activityType == 'EL_SURVEY'"
                  src="@/assets/img/activities/survey-icon.svg" />
                <img v-if="activity.activityType == 'WAT_CHAT'" src="@/assets/img/activities/chat-icon.svg" />
                <img v-if="activity.activityType == 'WAT_OPINION'" src="@/assets/img/activities/opinion-icon.svg" />
                <img v-if="activity.activityType == 'WAT_TASK'" src="@/assets/img/activities/task-icon.svg" />
                <img v-if="activity.activityType == 'WAT_POLL'" src="@/assets/img/activities/poll-icon.svg" />
                <img v-if="activity.activityType == 'VIDEOCHAT'" src="@/assets/img/activities/video-icon.svg" />
                <img v-if="activity.activityType == 'EL_HEATMAP'" src="@/assets/img/activities/heatmap-icon.svg" />
                <img v-if="activity.activityType == 'WAT_UX'" src="@/assets/img/activities/watux-icon.svg" />
              </div>
              <p>{{activity.activityName}}</p>
            </div>
            <p><span>{{activity.participatedNum}}/{{activity.userNum}}</span> {{$t('users')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="CommunityStatsPage--SocioDemoGraphics">
      <h1>{{$t('sociodemographic_data')}}</h1>
      <div class="CommunityStatsPage--SocioElements">
        <div class="CommunityStatsPage--SocioDemoGraphicsInfo">
          <div class="CommunityStatsPage--Sidebar">
            <h1>{{$t('members_load_age')}}</h1>
            <div class="CommunityStatsPage--SidebarList">
              <div class="CommunityStatsPage--SidebarElement">
                <p>{{$t('age_from_to',{from:20,to:30})}}</p>
                <p><span>{{stats.youngAgeUserNum}}</span> {{$t('users')}}</p>
              </div>
              <div class="CommunityStatsPage--SidebarElement">
                <p>{{$t('age_from_to',{from:30,to:50})}}</p>
                <p><span>{{stats.intermediateAgeUserNum}}</span> {{$t('users')}}</p>
              </div>
              <div class="CommunityStatsPage--SidebarElement">
                <p>{{$t('age_more_than',{count:50})}}</p>
                <p><span>{{stats.oldAgeUserNum}}</span> {{$t('users')}}</p>
              </div>
            </div>
          </div>
          <div class="CommunityStatsPage--Sidebar">
            <h1>{{$t('members_load_gender')}}</h1>
            <div class="CommunityStatsPage--SidebarList">
              <div class="CommunityStatsPage--SidebarElement">
                <p>{{$t('gender_M')}}</p>
                <p><span>{{stats.manNum}}</span> {{$t('users')}}</p>
              </div>
              <div class="CommunityStatsPage--SidebarElement">
                <p>{{$t('gender_W')}}</p>
                <p><span>{{stats.womanNum}}</span> {{$t('users')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="CommunityStatsPage--Map" id="membersMap">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import Highcharts from "highcharts/highmaps.js";
// import highmaps from '@/components/highchartsScripts/highmaps.js'
import maps from '@/components/highchartsScripts/es-all.json'
import mapsIT from '@/components/highchartsScripts/it-all.json'
import mapsPT from '@/components/highchartsScripts/pt-all.json'
import mapsUS from '@/components/highchartsScripts/us-all.json'
import mapsFR from '@/components/highchartsScripts/fr-all.json'
import mapsBE from '@/components/highchartsScripts/be-all.json'
import mapsMX from '@/components/highchartsScripts/mx-all.json'
// import exporting from '@/components/highchartsScripts/exporting.js'
// import markerclusters from '@/components/highchartsScripts/marker-clusters.js'
import { COMMUNITY_FETCH_MEMBERS_STATISTICS } from '@/store/community/members.module';

export default {
  name: 'CommunityStatsPage',
  data(){
    return{
      Highcharts:Highcharts,
      stats:{},
      loading: false,
    }
  },
  computed: {
    ...mapGetters([
      'theme',
      'country'
    ]),
  },
  methods: {
    setMaps(){
      Highcharts.maps["countries/es/es-all"] = maps
      Highcharts.maps["countries/it/it-all"] = mapsIT
      Highcharts.maps["countries/pt/pt-all"] = mapsPT
      Highcharts.maps["countries/us/us-all"] = mapsUS
      Highcharts.maps["countries/fr/fr-all"] = mapsFR
      Highcharts.maps["countries/be/be-all"] = mapsBE
      Highcharts.maps["countries/mx/mx-all"] = mapsMX
    }
  },
  async mounted(){
    this.setMaps()
    this.loading = true
    await store.dispatch(COMMUNITY_FETCH_MEMBERS_STATISTICS)
    .then((data) => {
      this.stats = data
      this.loading = false

    })
    var data = []
    var maxValue = 0
    for(let i in this.stats.provinceUsers){
      data.push([i,this.stats.provinceUsers[i]])
      if(this.stats.provinceUsers[i] > maxValue) maxValue = this.stats.provinceUsers[i]
    }
    const country = this.country.toLowerCase()
    const map = 'countries/'+country+'/'+country+'-all'

    Highcharts.mapChart('membersMap', {
        chart: {
            map: map
        },

        title: {
            text: null
        },
        credits: {
          style: {'display':'none'}
        },
        exporting: {
          enabled: false
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },

        colorAxis: {
            min: 0,
            max: maxValue,
            labels: {
                format: '{value}'
            },
            stops: [
                [0, '#F5F5F5'],
                [1, this.theme.mainColor]
            ]
        },

        series: [{
            data: data,
            name: 'Miembros',
            states: {
                hover: {
                    color: '#FD7328'
                }
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}'
            }
        }, {
            name: 'Separators',
            type: 'mapline',
            data: Highcharts.geojson(Highcharts.maps[map], 'mapline'),
            color: 'silver',
            nullColor: 'silver',
            showInLegend: false,
            enableMouseTracking: false
        }]
    });
  },
}
</script>

<style scoped lang="scss">
.CommunityStatsPage{
  h1{
    font-weight: normal;
    font-size: 19px;
    letter-spacing: -0.01em;
    color: #3E3E3E;
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  &--GeneralInfo{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    grid-gap: 20px;
    margin-bottom: 30px;
  }
  &--GeneralInfoBox{
    filter: drop-shadow(0px 1px 19px rgba(0, 0, 0, 0.1));
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:20px 0;
    p:first-of-type{
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 60px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #FF4149;
    }
    p:last-child{
      font-weight: bold;
      font-size: 25px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #3E3E3E;
      text-transform: capitalize;
    }
  }
  &--MoreInfo{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    grid-gap: 20px;
  }
  &--Sidebar{
    background: #F5F5F5;
    padding:20px 15px 20px 20px;
    margin-bottom: 23px;
  }
  &--SidebarList{
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: var(--primary-color);
    }
  }
  &--SidebarElement{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    p:first-of-type{
      background: #FFFFFF;
      border-radius: 14px;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #3E3E3E;
      padding: 2px 10px;
      display: flex;
      align-items: center;
    }
    p:last-of-type{
      font-size: 22px;
      letter-spacing: 0.01em;
      color: #3E3E3E;
      span{
        font-weight: bold;
      }
    }
  }
  &--Activities{
    padding:20px 15px 20px 20px;
    grid-column: span 2;
  }
  &--ActivityList{
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: var(--primary-color);
    }
  }
  &--Activity{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    p{
      font-size: 22px;
      letter-spacing: 0.01em;
      color: #3E3E3E;
      span{
        font-weight: bold;
      }
    }
  }
  &--ActivityContent{
    display: flex;
    p{
      font-weight: bold;
      font-size: 18px;
    }
    div{
      margin-right:20px;
      img{
        max-width: 31px;
      }
    }
  }
  &--SocioElements{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
    grid-gap: 20px;
  }
  &--Map{
    grid-column: span 2;
  }
  .showAll{
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #96C800;
    cursor: pointer;
    width:100%;
    text-align: right;
  }
  @media (max-width: 768px) {
    &--Sidebar{
      width: 100%;
    }
    &--SocioElements, &--MoreInfo{
      display: flex;
      flex-direction: column;
    }
  }
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  /* Centrado */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
