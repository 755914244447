import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5113182b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CardInput" }
const _hoisted_2 = ["type", "placeholder"]
const _hoisted_3 = ["type", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
    ]),
    (!_ctx.isTextarea)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
        }, null, 40, _hoisted_2)), [
          [_vModelDynamic, _ctx.value]
        ])
      : _createCommentVNode("", true),
    (_ctx.isTextarea)
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          type: _ctx.type,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur')))
        }, null, 40, _hoisted_3)), [
          [_vModelText, _ctx.value]
        ])
      : _createCommentVNode("", true)
  ]))
}