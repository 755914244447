<template>
  <div class="CommunityInput" :class="{'CommunityInput--WithError':error,'CommunityInput--Success':success && !disableSuccessClass}">
    <input
      class="CommunityInput--Input"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validate($event,true)"
      v-model="value"
      v-if="type == 'text' || type == 'email' || type == 'number'"
    />
    <input
      class="CommunityInput--Input"
      type="email"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validateIndependentEmail($event, true)"
      v-model="independentEmail"
      v-else-if="placeholderEmail"
    />
    <div class="CommunityInput--Error" v-show="errorIndependentEmail && !hideErrorMessage">
      {{errorIndependentEmail}}
    </div>


    <input
      class="CommunityInput--Input"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validate($event,true)"
      v-model="value"
      v-if="type == 'password'"
    />
    <input
      class="CommunityInput--Input"
      :type="'text'"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      @keyup="validateYoutubeLink($event,true)"
      v-model="value"
      v-if="type == 'youtube'"
    />
    <vue-editor
      class="CommunityInput--HtmlEditor"
      v-if="type == 'html'"
      v-model="value"
      ref="editor"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : ''"
      :editorOptions="editorOptions"
      @text-change="validate($event,true)">
    </vue-editor>
    <Datepicker
      ref="datePicker"
      @update:modelValue="validateDate($event, true)"
      :placeholder="placeholder"
      v-model="value"
      :format="format"
      :textInputOptions="textInputOptions"
      :disabled="disabled"
      :locale="lang"
      teleport-center
      :auto-position="true"
      :selectText = "$t('action_accept')"
      :cancelText = "$t('action_cancel')"
      time-picker-inline
      text-input
      :year-range="yearRange"
      :is-24="true"
      v-if="type == 'dateTesterProfile'"></Datepicker>
    <Datepicker
      ref="datePicker"
      @update:modelValue="validateDate($event, true)"
      :placeholder="placeholder"
      v-model="value"
      :format="format"
      :textInputOptions="textInputOptions"
      :disabled="disabled"
      :locale="lang"
      position="bottom-start"
      teleport="body"
      :auto-position="true"
      :selectText = "$t('action_accept')"
      :cancelText = "$t('action_cancel')"
      time-picker-inline
      text-input
      :year-range="yearRange"
      :is-24="true"
      v-if="type == 'date' || type == 'datetime'"></Datepicker>

    <textarea class="CommunityInput--Textarea" v-model="value" @keyup="validate($event,true)" :placeholder="placeholder ? placeholder : ''" v-if="type == 'textarea'"></textarea>
    <div class="CommunityInput--Error" v-show="error && !hideErrorMessage">
      {{error}}
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import Datepicker from '@vuepic/vue-datepicker';
import CommunityButton from '@/components/CommunityButton.vue'
import '@vuepic/vue-datepicker/dist/main.css'
import {verifyEmail} from '@/utils'
import { isEmptyText, isYouTubeVideo } from '@/utils';
import Swal from "sweetalert2";

export default {
  name: 'CommunityInput',
  components:{
    VueEditor,
    Datepicker,
    CommunityButton
  },
  data() {
    return {
      validationsNum:0,
      error       : false,
      success: false,
      editorOptions:{},
      customToolbar: [
        [{ 'font': [] }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'header': 1 }, { 'header': 2 }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'color': [] }, { 'background': [] }],
        ['link', this.withImages ? 'image': '', 'formula'],
        [{ 'direction': 'rtl' }],
        ['clean'],
      ],
      textInputOptions: {
        enterSubmit: true
      },
      independentEmail: '',
      errorIndependentEmail: false,
      successIndependentEmail: false,
      showImageSizePopup: false,
      imageWidthInput: '',  
      selectedImage: null,
    }
  },
  watch: {
   value (newValue) {
     if(typeof newValue === "object" && newValue != null) this.value = newValue.toString()
     this.$emit('update:modelValue', newValue)
   },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  emits: ['update:modelValue','change','key-up'],
  props: {
    modelValue         : [Number, String, Date],
    type               : String,
    disabled           : Boolean,
    required           : Boolean,
    email              : Boolean,
    minLength          : Number,
    maxLength          : Number,
    max                : {type:Number, default: 10000000000},
    placeholder        : String,
    lang               : String,
    format             : String,
    timePickerOptions  : Object,
    disableSuccessClass: Boolean,
    stopFirstValidation: Boolean,
    hideErrorMessage: Boolean,
    yearRange   : {
      type: Array,
      default: [1900, 2100]
    },
    passErrorMessage:{
      type:String,
      default:''
    },
    placeholderEmail: String,
    withImages:{
      type:Boolean,
      default:false
    }
  },
  created() {
    this.editorOptions = {
      modules: {
        toolbar: {
          container: this.customToolbar,
          handlers: {
            image: this.withImages ? this.imageHandler : null ,
          },
        },
      },
    };
  },
  methods: {
    async imageHandler() {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        if (!file) return;

        const maxSize = 1048576; // 1MB
        if (file.size > maxSize) {
          Swal.fire({
            text: this.$t('custom_mail_image_size_limit'),
            icon: "error",
            customClass: { popup: "swal-customError" },
          });
          return;
        }

        const fileDataURL = await this.fileToDataURL(file);

        // Cargar la imagen para obtener las dimensiones originales
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = fileDataURL;
        });

        let originalWidth = img.naturalWidth;
        let originalHeight = img.naturalHeight;

        const { value: formValues } = await Swal.fire({
          title: this.$t('set_image_dimensions'),
          width: '100%',
          html: `
          <div style="display:flex;flex-direction:column;align-items:center;">
            <div style="margin-bottom:10px;display:flex;gap:10px;">
              <input id="width-input" class="swal2-input" style="width:100px;" placeholder="${this.$t('width')}" type="number" value="${originalWidth}">
              <input id="height-input" class="swal2-input" style="width:100px;" placeholder="${this.$t('height')}" type="number" value="${originalHeight}">
            </div>
            <div id="resize-container" style="position: relative; display: inline-block; border: 1px solid #ccc; overflow:hidden;">
              <img id="preview-img" src="${fileDataURL}" style="display:block; width:${originalWidth}px; height:${originalHeight}px;"/>
              <div id="resize-handle" style="position:absolute; bottom:0; right:0; width:20px; height:20px; cursor:se-resize;">
                <img src="../assets/img/resize.svg" alt="Resize" style="width:100%;height:100%;object-fit:contain;" />
              </div>
            </div>
          </div>
        `,
          showCancelButton: true,
          confirmButtonText: this.$t('action_accept'),
          cancelButtonText: this.$t('action_cancel'),
          focusConfirm: false,
          didOpen: () => {
            const popup = Swal.getPopup();
            const previewImg = popup.querySelector('#preview-img');
            const resizeHandle = popup.querySelector('#resize-handle');
            const widthInput = popup.querySelector('#width-input');
            const heightInput = popup.querySelector('#height-input');

            let isResizing = false;
            let startX, startY, startWidth, startHeight;

            const updateInputsFromImage = () => {
              const w = parseInt(window.getComputedStyle(previewImg).width, 10);
              const h = parseInt(window.getComputedStyle(previewImg).height, 10);
              widthInput.value = w;
              heightInput.value = h;
            };

            const updateImageFromInputs = () => {
              const w = parseInt(widthInput.value, 10);
              const h = parseInt(heightInput.value, 10);
              if (!isNaN(w) && w > 0) previewImg.style.width = w + 'px';
              if (!isNaN(h) && h > 0) previewImg.style.height = h + 'px';
            };

            // Actualizar imagen al cambiar inputs
            widthInput.addEventListener('input', updateImageFromInputs);
            heightInput.addEventListener('input', updateImageFromInputs);

            const mouseMoveHandler = (e) => {
              if (!isResizing) return;
              const dx = e.clientX - startX;
              const dy = e.clientY - startY;

              const newWidth = Math.max(10, startWidth + dx);
              const newHeight = Math.max(10, startHeight + dy);

              previewImg.style.width = newWidth + 'px';
              previewImg.style.height = newHeight + 'px';

              // Actualizar inputs con el nuevo tamaño mientras se arrastra
              widthInput.value = newWidth;
              heightInput.value = newHeight;
            };

            const mouseUpHandler = () => {
              isResizing = false;
              document.removeEventListener('mousemove', mouseMoveHandler);
              document.removeEventListener('mouseup', mouseUpHandler);
            };

            resizeHandle.addEventListener('mousedown', (e) => {
              e.preventDefault();
              isResizing = true;
              startX = e.clientX;
              startY = e.clientY;
              startWidth = parseInt(window.getComputedStyle(previewImg).width, 10);
              startHeight = parseInt(window.getComputedStyle(previewImg).height, 10);

              document.addEventListener('mousemove', mouseMoveHandler);
              document.addEventListener('mouseup', mouseUpHandler);
            });
          },
          preConfirm: () => {
            const popup = Swal.getPopup();
            const previewImg = popup.querySelector('#preview-img');
            const w = parseInt(window.getComputedStyle(previewImg).width, 10);
            const h = parseInt(window.getComputedStyle(previewImg).height, 10);
            if (isNaN(w) || isNaN(h) || w <= 0 || h <= 0) {
              Swal.showValidationMessage(this.$t('invalid_dimensions'));
              return false;
            }
            return { width: w, height: h };
          }
        });

        if (!formValues) return; // Usuario canceló
        const { width, height } = formValues;

        try {
          const resizedFile = await this.resizeImage(file, width, height);
          const response = await this.$store.dispatch('UPLOAD_IMG_TO_S3', {
            file: resizedFile,
            publicLogo: "true"
          });

          const imageUrl = response.path;
          const range = this.$refs.editor.quill.getSelection();
          if (range) {
            this.$refs.editor.quill.insertEmbed(range.index, 'image', imageUrl);
          } else {
            this.$refs.editor.quill.insertEmbed(this.$refs.editor.quill.getLength() - 1, 'image', imageUrl);
          }
        } catch (error) {
          Swal.fire({
            text: `${this.$t('custom_mail_image_upload_error')}: ${error.message || error}`,
            icon: "error",
            customClass: { popup: "swal-customError" },
          });
        }
      };
    },

    fileToDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => resolve(e.target.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },

    resizeImage(file, width, height) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(blob => {
              if (blob) {
                const resizedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
                resolve(resizedFile);
              } else {
                reject(new Error('Error resizing image'));
              }
            }, file.type);
          };
          img.onerror = () => reject(new Error('Error loading image'));
          img.src = e.target.result;
        };
        reader.onerror = () => reject(new Error('Error reading file'));
        reader.readAsDataURL(file);
      });
    },
    

    validateDate(event,emit){
      this.error = false
      this.success = false
      let hasError = false
      if(this.required && ((this.value == undefined || this.value == null) && (event == undefined || event == null)) ){
        this.error = this.$t('validation_required')
        hasError = true
      }
      if(!hasError) this.success = true
      if(emit) this.$emit('change',event)
      return hasError
    },
    validate(event,emit){
      if(this.stopFirstValidation && this.validationsNum == 0){
        this.validationsNum++
        return
      }
      this.error = false
      this.success = false
      let hasError = false
      if(this.type == 'password' && this.passErrorMessage.length > 0 && !this.validatePassword()){
        this.error = this.passErrorMessage
        hasError = true
      }
      else if(this.required && ( this.value == undefined || this.value.length == 0)){
        this.error = this.$t('validation_required')
        hasError = true
      }
      else if(this.required && this.minLength > this.value.length){
        this.error = this.$t('validation_min_length') + this.minLength
        hasError = true
      }
      else if(this.required && this.type == 'email' && !verifyEmail(this.value)){
        this.error = this.$t('validation_email')
        hasError = true
      }
      else if(this.type == "number" && parseInt(this.value) > this.max){
        this.error = this.$t('validation_max_number', { max:this.max })
        hasError = true
      }
      if(!hasError) this.success = true
      if(emit) this.$emit('key-up',{event:event,error:hasError})
      return hasError
    },
    validatePassword(){
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
      const valid = passwordRegex.test(this.value);
      return valid
    },
    validateYoutubeLink(event,emit){
      this.error = false
      this.success = false
      let hasError = false
      if(this.required && isEmptyText(this.value)){
        this.error = this.$t('validation_required')
        hasError = true
      }
      if(!isEmptyText(this.value) && !isYouTubeVideo(this.value)){
        this.error = this.$t('validation_youtubelink')
        hasError = true
      }

      if(!hasError) this.success = true
      if(emit)this.$emit('key-up',{event:event,error:hasError})
      return hasError
    },
    validateIndependentEmail(event, emit) {
      this.errorIndependentEmail = false
      this.successIndependentEmail = false
      let hasError = false

      if (this.required && (this.independentEmail == undefined || this.independentEmail.length == 0)) {
        this.errorIndependentEmail = this.$t('validation_required')
        hasError = true
      } else if (!verifyEmail(this.independentEmail)) {
        this.errorIndependentEmail = this.$t('validation_email')
        hasError = true
      }

      if (!hasError) this.successIndependentEmail = true
      if (emit) this.$emit('key-up', { event: event, error: hasError })
      return hasError
    },
  },
}
</script>

<style lang="scss">
.CommunityInput{
  .mx-input-append{
    background: transparent;
    margin: 5px;
    svg{
      color: var(--accent-color);
    }
  }
  &--HtmlEditor{
    .ql-toolbar{
      position:sticky;
      top:0;
      z-index: 1;
      background-color: white;
    }
    .ql-editor{
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      margin-top:13px;
      color: #969696;
      font-size: 22px;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      img {
          max-width: 100%;
          height: auto;
          cursor: pointer;
        }
    }
    .ql-blank{
      &:before{
        font-style:normal!important;
      }
    }
  }
  &--WithError{
    input,.ql-editor, .CommunityInput--Textarea{
      background: #FFF0F0!important;
      border: 2px solid #F98787!important;
    }
  }
  &--Success{
    input,.ql-editor{
      border: 2px solid #ACE949!important;
    }
  }
}

</style>
<style lang="scss" scoped>
.CommunityInput{
  position: relative;
  &--Input{
    background      : #f4f4f4;
    border          : 1px solid #e4e4e4;
    font-size       : 1.3rem;
    padding         : .5rem 1rem;
    width           : 100%;
    font-family: Roboto condensed;
    letter-spacing: 0.01em;
    outline: none;
    &::placeholder{
      opacity: 0.6;
      @media only screen and (max-width: 600px) {
      font-size: 20px;
      }
    }
  }
  &--Textarea{
    width: 100%;
    height: 150px;
    resize:none;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    letter-spacing: 0.01em;
    color: #969696;
    //background: #F5F5F5;
    border: 1px solid #D3D3D3;
    padding: 10px;
    outline: none;
  }
  &--Error{
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #F40707;
    position: absolute;
    z-index:2;
  }
}

.image-size-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
}
</style>
