import ApiService from '@/services/api.service';

export const COMMUNITY_FETCH_PENDING_REDEEMS = 'COMMUNITY_FETCH_PENDING_REDEEMS';
export const COMMUNITY_FETCH_COMPLETE_REDEEMS = 'COMMUNITY_FETCH_COMPLETE_REDEEMS';
export const COMMUNITY_COMPLETE_REDEEM = 'COMMUNITY_COMPLETE_REDEEM';
export const COMMUNITY_ADD_REDEEM = 'COMMUNITY_ADD_REDEEM';
export const COMMUNITY_FETCH_POINTS_EXCEL = 'COMMUNITY_FETCH_POINTS_EXCEL';
export const COMMUNITY_SET_REDEEM_LOADING = 'COMMUNITY_SET_REDEEM_LOADING';
export const COMMUNITY_SET_REDEEM_ERROR = 'COMMUNITY_SET_REDEEM_ERROR';
export const COMMUNITY_FETCH_SET_RAFFLE_DATA = 'COMMUNITY_FETCH_SET_RAFFLE_DATA';
export const COMMUNITY_FETCH_PENDING_RAFFLE = 'COMMUNITY_FETCH_PENDING_RAFFLE';
export const COMMUNITY_FETCH_HAS_RAFFLE_DATA = 'COMMUNITY_FETCH_HAS_RAFFLE_DATA';
export const COMMUNITY_COMPLETE_PENDING_RAFFLE = 'COMMUNITY_COMPLETE_PENDING_RAFFLE';
export const COMMUNITY_COMPLETE_ASK_NOTIFICATION = 'COMMUNITY_COMPLETE_ASK_NOTIFICATION';

const initialState = {
  redeem: {
    hasRaffle: false,
    state: {},
    loading: false,
    error: null,
  },
};

const state = initialState;

const getters = {
  communityHasRaffle(state) {
    return state.redeem.hasRaffle;
  },
};

const actions = {
  [COMMUNITY_ADD_REDEEM] (context,payload) {
    context.commit(COMMUNITY_SET_REDEEM_LOADING);
    return ApiService
      .post('/experienceLabs/' + context.getters.communityId + '/redeemPoints',payload.data)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_REDEEM_ERROR, response);
      });
  },
  [COMMUNITY_FETCH_PENDING_REDEEMS] (context,payload) {
    const typeParam = payload.filters?.type ? `&type=${payload.filters.type}` : ''
    const startDateParam = payload.filters?.initialDate ? `&initialDate=${payload.filters.initialDate}` : ''
    const endDateParam = payload.filters?.endDate ? `&endDate=${payload.filters.endDate}` : ''

    context.commit(COMMUNITY_SET_REDEEM_LOADING);
    return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/pendingRedeems?page=' + payload.page + '&pageSize=15' + typeParam + startDateParam + endDateParam)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_REDEEM_ERROR, response);
      });
  },
  [COMMUNITY_FETCH_COMPLETE_REDEEMS] (context,payload) {
    const typeParam = payload.filters?.type ? `&type=${payload.filters.type}` : ''
    const startDateParam = payload.filters?.initialDate ? `&initialDate=${payload.filters.initialDate}` : ''
    const endDateParam = payload.filters?.endDate ? `&endDate=${payload.filters.endDate}` : ''

    context.commit(COMMUNITY_SET_REDEEM_LOADING);
    return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/completeRedeems?page=' + payload.page + '&pageSize=15' + typeParam + startDateParam + endDateParam)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_REDEEM_ERROR, response);
      });
  },
  [COMMUNITY_COMPLETE_REDEEM] (context,payload) {
    context.commit(COMMUNITY_SET_REDEEM_LOADING);
    return ApiService
      .post('/experienceLabs/client/communities/' + context.getters.communityId + '/completeRedeems',payload.data)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_REDEEM_ERROR, response);
      });
  },
  [COMMUNITY_FETCH_POINTS_EXCEL] (context,payload) {
    return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/pointsExcel', null, {
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_PENDING_RAFFLE] (context,payload) {
    return ApiService
      .get(`/experienceLabs/client/community/${context.getters.communityId}/raffleList`)
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_HAS_RAFFLE_DATA] (context,payload) {
    return ApiService
      .get(`/experienceLabs/client/community/${context.getters.communityId}/hasRaffleData`)
      .then(({data}) => {
        context.commit(COMMUNITY_FETCH_SET_RAFFLE_DATA, data);
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_COMPLETE_PENDING_RAFFLE] (context,payload) {
    return ApiService
      .post(`/experienceLabs/client/community/${context.getters.communityId}/activity/${payload.activityId}/markRaffleRedeemed`)
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_COMPLETE_ASK_NOTIFICATION] (context,payload) {
    return ApiService
      .post(`/experienceLabs/client/community/${context.getters.communityId}/activity/${payload.activityId}/askNotification`)
      .then(({data}) => data)
      .catch(({response}) => response);
  },
};

const mutations = {

  [COMMUNITY_SET_REDEEM_LOADING] (state) {
    state.tags.loading = true;
  },

  [COMMUNITY_SET_REDEEM_ERROR] (state, error) {
    state.tags.error = error;
    state.tags.loading = false;
  },

  [COMMUNITY_FETCH_SET_RAFFLE_DATA] (state, payload) {
    state.redeem.hasRaffle = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
