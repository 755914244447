<template>
  <div class="WatUxMonitor">
    <div class="top">
      <p>
        {{$t('watmonitor_ux_total')}}
        <span
          >{{ userList.length }}
          {{ userList.length > 1 ? $t('watmonitor_videos') : $t('watmonitor_video') }}</span
        >
      </p>
      <div class="filter">
        <div id="filter" @click="openFilterModal">
          <p>{{$t('watmonitor_filter_results')}}</p>
          <IconArrowDown />
        </div>
        <UxVideosFilter
          class="filterModal"
          :filter="filter"
          v-show="showFilterModal"
          @closeFilterModal="closeFilterModal"
        />
      </div>
    </div>
    <div class="bottom">
      <UxVideoPrevInfoBoxList
      :uxUserList="userList"
      :filter="filter"
      :testId="testId"
      :taskNum="taskNum"
      :activityTitle="activityTitle"
      :activityType="activityType"
      :communityTranscriptions="props.communityTranscriptions"
      :isActivity="isActivity"
      />
    </div>
  </div>
</template>
<script setup>
import { reactive, computed, ref, onBeforeMount } from 'vue'
import UxVideosFilter from './Ux/UxVideosFilter.vue'
import UxVideoPrevInfoBoxList from './Ux/UxVideoPrevInfoBoxList.vue'
import IconArrowDown from './icons/IconArrowDown.vue'

const showFilterModal = ref(false)
const props = defineProps({
  uxData: Object,
  communityTranscriptions: Array,
  activityTitle: String,
  isActivity: {
    type: Boolean,
    default: false,
  },
  testId: Number,
  taskNum: Number,
  i18n: Object,
  activityType:String,
})
onBeforeMount(()=>{
})
const userList = computed(() => {
  let userList = []

  if (props.isActivity) {
    userList = props.uxData[0].uxDatas.map((data, index)=> {
      let userFormated = data
      userFormated.name = data.nickname
      return userFormated
    })
    return userList
  }
  if (props.uxData?.yLabels.length > 0) {
    for (let labelIndex in props.uxData?.yLabels) {
      for (let ud of props.uxData?.uxDatas[labelIndex]) {
        for (let udIndex in ud) {
          let userFormated = ud[udIndex]
          userFormated.name =
            props.uxData?.yLabels[labelIndex] + ' ' + (parseInt(udIndex) + 1)
          userList.push(userFormated)
        }
      }
    }
  } else {
    let index = 1
    for (let user of props.uxData?.uxDatas[0][0]) {
      let userFormated = user
      userFormated.name = props.i18n.global.t('watmonitor_user') + ' ' + index
      index++
      userList.push(userFormated)
    }
  }
  return userList
})
const openFilterModal = () => {
  showFilterModal.value = true
}
const closeFilterModal = () => {
  showFilterModal.value = false
}
const filter = reactive({
  platformType: {
    DESKTOP: true,
    MOBILE: true,
    TABLET: true,
    UNKNOWN: true,
    FULLDESKTOP: true,
    FULLMOBILE: true,
    FULLTABLET: true,
  },
  platform: {
    IOS: true,
    ANDROID: true,
    WINDOWS: true,
    LINUX: true,
    OSX: true,
    OTHER: true,
    UNKNOWN: true,
  },
  browser: {
    IE: true,
    MOBILEIE: true,
    MOZILLA: true,
    MOBILEMOZILLA: true,
    CHROME: true,
    MOBILECHROME: true,
    SAFARI: true,
    MOBILESAFARI: true,
    OPERA: true,
    MOBILEOPERA: true,
    OTHER: true,
    UNKNOWN: true,
    EDGE: true,
    APP: true,
  }
})
</script>
<style lang="scss" scoped>
.WatUxMonitor {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 25px;
  *{
    text-align: initial;
  }
  .top {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    > p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      color: #000000;
      > span {
        color: #8134ff;
      }
    }
    .filter {
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      width: fit-content;
      height: fit-content;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      cursor: pointer;
      #filter {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        * {
          pointer-events: none;
        }
        > p {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #626262;
        }
      }
      .filterModal {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
      }
    }
  }
}
</style>
