<template>
  <div id="uxVideoPrevInfoBoxList">
    <template v-if="!isActivity" v-for="execution in executions">
      <UxVideoPrevInfoBox
        v-show="meetsFilter(execution.userAgentData)"
        :testId="testId"
        :taskNum="execution.ttnum"
        :device="execution?.userAgentData.platformType"
        :system="execution?.userAgentData.platform"
        :browser="execution?.userAgentData.browser"
        :screenVideoUrl="execution.screenVideoUrl"
        :webcamVideoUrl="execution.webcamVideoUrl"
        :isVideoSeen="execution.videoViewed"
        :videoDate="execution.date"
        :executionId="execution.executionId"
        :transcription="execution.transcription"
        @setUxVideoInfoInstance="setUxVideoInfoInstance"
        :isActivity=false
      />
    </template>
    <template v-if="isActivity" v-for="execution in executions">
      <UxVideoPrevInfoBox
        :screenVideoUrl="execution.screenVideoUrl"
        :webcamVideoUrl="execution.webcamVideoUrl"
        :activityTitle="activityTitle"
        :activityType="activityType"
        :userNickname="execution.nickname"
        @setUxVideoInfoInstance="setUxVideoInfoInstance"
        :isActivity=true
        :communityTranscriptions=" props.communityTranscriptions"
      />
    </template>
  </div>
</template>
<script setup>
import { reactive, onMounted, inject, ref, onBeforeMount } from 'vue'
import ApiService from '../../../services/api.service'
import UxVideoPrevInfoBox from './UxVideoPrevInfoBox.vue'
const props = defineProps({
  communityTranscriptions: Array,
  isActivity: {
    type: Boolean,
    default: false,
  },
  activityTitle: String,
  activityType:String, 
  uxUserList: {
    type: Array,
    default: [],
  },
  filter: {
    type: Object,
  },
  testId: Number,
  taskNum: Number,
})

const emitter = inject('emitter')
const UxVideoInfoInstance = ref(null)
const setUxVideoInfoInstance = (instance) => {
  UxVideoInfoInstance.value = instance
}
const executions = ref([])

onBeforeMount(async () => {
  
  if (!props.isActivity) {
    const data = await ApiService.get(
      `/clientDashboard/test/${props.testId}/task/${props.taskNum}/uxDatas`
    )
    props.uxUserList.forEach((userExec, index) => {
      //añado datos 'extra' de Ux a la ejecución
      let execution = { ...userExec }
      const executionData = data.data.dashboardUxDataList.find(
        (exec) => exec.id.executionId == userExec.executionId
      )
      if (executionData) {
        execution.videoViewed = executionData.viewed
      }
      //añado la transcripción a la ejecución (si tiene)
      let transcriptionData = null
      if (
        data.data.audioTranscriptionList &&
        data.data.audioTranscriptionList.length > 0
      ) {
        transcriptionData = data.data.audioTranscriptionList.find(
          (trans) => trans.originalVideoUrl == userExec.screenVideoUrl || trans.originalVideoUrl == userExec.webcamVideoUrl
        )
      }
      execution.transcription =
        transcriptionData == null ? null : transcriptionData.transcriptionLines
      //añado la ejecución al listado de ejecuciones
      executions.value[index] = execution
    })
  } else {
    
      props.uxUserList.forEach((userExec, index) => {
      let execution = { ...userExec }
      executions.value[index] = execution
    })
  }

})
onMounted(async () => {
  emitter.on('closeUxVideoInfoModal', async (event) => {
    if (
      UxVideoInfoInstance.value != null &&
      UxVideoInfoInstance.value != undefined
    ) {
      await UxVideoInfoInstance.value.destroy()
      UxVideoInfoInstance.value = null
    }
  })
  

})

const meetsFilter = (userAgentData) => {
  let meetsPlatformType = false
  let meetsPlatform = false
  let meetsBrowser = false
  for (const platformType in props.filter.platformType) {
    const value = props.filter.platformType[platformType]
    if (platformType == userAgentData.platformType && value == true) {
      meetsPlatformType = true
    }
  }
  for (const platform in props.filter.platform) {
    const value = props.filter.platform[platform]
    if (platform == userAgentData.platform && value == true) {
      meetsPlatform = true
    }
  }
  for (const browser in props.filter.browser) {
    const value = props.filter.browser[browser]
    if (browser == userAgentData.browser && value == true) {
      meetsBrowser = true
    }
  }
  /* for (const clientType in props.filter.clientType) {
    const value = props.filter.clientType[clientType]
    if (clientType == userAgentData.clientType && value == true) {
      meetsClientType = true
    }
  } */
  return meetsPlatformType && meetsPlatform && meetsBrowser
}
</script>
<style lang="scss" scoped>
#uxVideoPrevInfoBoxList {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}
</style>
