<template>
	<div class="SelectionSearch">
		<template v-if="!selectedOption">
			<input type="text" v-model="textToSearch" class="SelectionSearch__Search"
				:placeholder="$t('selection_search_filter_text')" @input="filterOptions" />
			<div class="SelectionSearch__Dropdown" v-if="textToSearch.length > 0">
				<div v-for="(option, index) in filteredOptions" v-if="filteredOptions.length > 0" :key="index" class="SelectionSearch__Option" @click="selectOption(option)">
					{{ option.value }}
				</div>
				<div v-else class="SelectionSearch__Error">
					{{ $t('selection_search_filter_error') }}
				</div>
			</div>
		</template>
		<div v-else class="SelectionSearch__Selected">
			<span>{{ selectedOption.value }}</span>
			<button class="SelectionSearch__Clear" @click="removeSelectedOption">✖</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectionSearch',
	props: {
		question: {
			type: Object,
			default: null,
		}
	},
	data() {
		return {
			textToSearch: "",
			filteredOptions: [],
			selectedOption: null
		};
	},
	methods: {
		filterOptions() {
			const searchTerms = this.textToSearch.toLowerCase().split(/\s+/);
			this.filteredOptions = this.question.question.values.filter(option => {
				const optionText = option.value.toLowerCase().split(/\s+/);
				let searchIndex = 0;
				if (!optionText[0].startsWith(searchTerms[0])) {
					return false;
				}
				return searchTerms.every(term => {
					while (searchIndex < optionText.length) {
						if (optionText[searchIndex].startsWith(term)) {
							searchIndex++;
							return true;
						}
						searchIndex++;
					}
					return false;
				});
			});
		},
		selectOption(option) {
			this.selectedOption = option;
			this.textToSearch = "";
			this.$emit('send-answers', {
				answer: { val: this.selectedOption.id },
				question: this.question,
				answerValid: true,
			});
		},
		removeSelectedOption() {
			this.selectedOption = null;
			this.filteredOptions = this.question.question.values;
			this.$emit('send-answers', {
				answer: { val: this.selectedOption },
				question: this.question,
				answerValid: false
			});
		}
	},
	mounted() {
		this.filteredOptions = this.question.question.values;
		if (this.question.answer) {
			this.selectedOption = this.question.question.values.find(
				option => option.id === this.question.answer.val
			);
			this.$emit('send-answers', {
				answer: { val: this.selectedOption },
				question: this.question,
				answerValid: true
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.SelectionSearch {
	display: flex;
	flex-direction: column;
	position: relative;

	&__Search {
		padding: 8px;
		border: 1px solid #aeaeae;
		border-radius: 4px;
		margin-bottom: 10px;
		outline: none;
	}

	&__Dropdown {
		background: white;
		border: 1px solid #aeaeae;
		border-radius: 4px;
		max-height: 200px;
		overflow-y: auto;
		z-index: 10;
		width: 100%;
	}

	&__Option {
		padding: 8px;
		cursor: pointer;

		&:hover {
			background-color: #f0f0f0;
		}
	}

	&__Error {
		color: white;
		background-color: rgb(239 13 13);
	}

	&__Selected {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px;
		border: 1px solid #aeaeae;
		border-radius: 4px;
		background-color: #f9f9f9;
	}

	&__Clear {
		background: none;
		border: none;
		cursor: pointer;
		font-size: 16px;
		color: #ff5c5c;

		&:hover {
			color: #d9534f;
		}
	}
}
</style>
