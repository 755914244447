<template lang="html">
  <div class="ChatPageV2">
    <div class="ChatPageV2--Filter">
      <Tabs class="ChatPageV2--Tabs" :tabs="orderFilters" :activeOnLoad="orderFilters[0].id"
        v-on:change="handleChangeOrderFilter" ref="TabsComponent" />
      <div class="ChatPageV2--Search">
        <SearchBar v-show="order == orderFilters[2].id" class="SearchBarV2" :placeholder="$t('filter_nickname')"
          v-on:key-up="handleItemSelected" />
      </div>
      <div class="ChatPageV2--ChatList">
        <div class="ChatPageV2--moderatorChat" :class="{ selected: observatorSelected }" v-on:click="openChat('OBSERVER')">
          <Avatar :src="communityInfo.moderatorLogo" :allowLabs="false" />
          <span v-if="isModerator">{{ $t('role_observator') }}</span>
          <span v-else>{{ $t('role_moderator') }}</span>
        </div>
        <div class="ChatPageV2--user" v-for="chat in communityChatList"
          v-if="!isObservator && (order == 'recent' || order == 'all')">
          <CommunityUser :hideGamification="communityInfo.hideGamification" :class="{ selected: chat.selected }"
            v-on:click.native="openChat(chat.identifier)" :member="chat" :allowRemind="false" />
          <Badge :count="'' + chat.notSeenCount + ''" v-if="chat.notSeenCount > 0" />
        </div>
        <div class="ChatPageV2--user" v-for="chat in communityChatList"
          v-if="!isObservator && order == 'unseen' && !unseenChatIsEmpty">
          <CommunityUser :hideGamification="communityInfo.hideGamification" :class="{ selected: chat.selected }"
            v-on:click.native="openChat(chat.identifier)" :member="chat" :allowRemind="false"
            v-if="chat.notSeenCount > 0 || (chat.notSeenCount <= 0 && chat.selected)" />
          <Badge :count="'' + chat.notSeenCount + ''" v-if="chat.notSeenCount > 0" />
        </div>
        <div class="ChatPageV2--empty" v-if="!isObservator && order == 'unseen' && unseenChatIsEmpty">
          <h2>{{ $t('empty_unseen_chat') }}</h2>
        </div>
      </div>
    </div>
    <GradientBackLayout class="ChatPageV2--Chat" :title="$filters.translate('chat')"
      :shadowText="$filters.translate('chat').toUpperCase()">
      <Chat ref="chat" :moderatorUser="isModerator" v-on:send-message="handleSendMessage"
      @delete-chat-message="handleDeleteChatMessage"
        :messages="observatorSelected ? communityChatMessagesObs : communityChatMessages"
        :members="communityChatList" />
    </GradientBackLayout>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import { map, flatMap } from 'lodash';
import Chat from '~/components/WatFocus/Chat.vue'
import Tabs from '~/components/Tabs';
import Badge from '~/components/Badge';
import Avatar from '~/components/Avatar';
import $ from 'jquery';
import {
  COMMUNITY_FETCH_CHAT_LIST,
  COMMUNITY_FETCH_CHAT_MESSAGES,
  COMMUNITY_SEE_CHAT_MESSAGES,
  COMMUNITY_SEE_CHAT_MESSAGES_OBS,
  COMMUNITY_ADD_CHAT_MESSAGE,
  COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE,
  COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS,
  COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS,
  COMMUNITY_DELETE_CHAT_MESSAGE
} from '@/store/community/chat.module';
import Pusher from 'pusher-js';
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import CommunityUser from '@/components/CommunityUser'

export default {
  name: 'ChatPage',

  components: {
    Chat,
    Tabs,
    Badge,
    Avatar,
    GradientBackLayout,
    BoxShadow,
    SearchBar,
    CommunityUser
  },

  computed: {
    ...mapGetters([
      'communityId',
      'communitySelectedChat',
      'communityChatList',
      'communityChatMessages',
      'communityChatMessagesObs',
      'isClient',
      'isModerator',
      'isObservator',
      'isUser',
      'origin',
      'theme',
      'communityInfo',
      'onlineUsers',
    ]),
  },

  data() {
    return {
      orderFilters: [
        { id: 'unseen', title: 'filter_unseen', },
        { id: 'recent', title: 'filter_recent', },
        { id: 'all', title: 'filter_all', },
      ],
      photoUploadUrl: process.env.VUE_APP_API_URL + '/experienceLabs/file',
      filter: '',
      order: '',
      observatorSelected: true,
      page: 0,
      pageSize: 50,
      isLoadingChats: false,
      currentRequest: null,
      debounceTimeout: null,
      unseenChatIsEmpty: true,
      isLoadingChats: false,
      isScrollingToChat: false, 
    }
  },

  methods: {
    ...mapMutations({
      changeChat: 'changeChat',
      enlargePic: 'enlargePic',
      chatOnline: 'chatOnline',
    }),
    handleItemSelected(e, text) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.searchBarEvent(e, text);
      }, 500);
    },
    async searchBarEvent(e, text) {
      this.filter = text
      this.page = 0
      this.pageSize = 1000
      let data = {
        order: 'all',
        filter: this.filter,
        page: this.page,
        pageSize: this.pageSize,
        updateChatList: false
      }

      const request = store.dispatch(COMMUNITY_FETCH_CHAT_LIST, data)

      this.currentRequest = request;
      await request;
      if (this.currentRequest !== request) return;

      this.isLoadingChats = false
      this.changeChat(0)
      this.fetchOnlineUsers()
    },
    handleChangeOrderFilter: async function (e) {
      this.fetchChatList(e)
    },
    async pageScroll(e) {
      if (this.isLoadingChats) return
      const { scrollTop, scrollHeight, clientHeight } = document.getElementsByClassName('ChatPageV2--ChatList')[0];
      if (scrollTop + clientHeight >= scrollHeight - 5 && this.order == 'all') {
        if (this.page == 0 && this.pageSize > 10) {
          this.page = this.pageSize / 10
          this.pageSize = 10
        }
        else this.page++
       await this.fetchChatList(this.order, true)
      }
    },
    fetchChatList: async function (e, isPageScroll) {
      const lastOrder = this.order
      this.order = e
      if (!((lastOrder == "recent" && this.order == "unseen") || (lastOrder == "unseen" && this.order == "recent"))) {
        this.isLoadingChats = true
        if (!isPageScroll && this.order == 'all' && this.page > 0) {
          this.pageSize = (this.page + 1) * this.pageSize
          this.page = 0
        }
        let data = {
          order: e,
          filter: this.filter,
          page: this.order == 'all' ? this.page : undefined,
          pageSize: this.order == 'all' ? this.pageSize : undefined,
          updateChatList: this.order == 'all' && lastOrder == 'all'
        }
        await store.dispatch(COMMUNITY_FETCH_CHAT_LIST, data)
          .then((data) => {
            this.isLoadingChats = false
            if (!isPageScroll) this.changeChat(0)
            this.fetchOnlineUsers()
          })
      }
    },

    openChat: async function (identifier) {
      this.observatorSelected = false
      if (identifier == "OBSERVER") {
        await this.fetchChatMessagesObs()
        this.changeChat(-1)
        this.observatorSelected = true
        if (this.isObservator != null && !this.isObservator) this.seeChatMessagesObs()
        return
      }
      this.changeChat(identifier)
      await this.fetchChatMessages()
      this.seeChatMessages()
      $('html,body').animate({ scrollTop: 0 }, 800);
      this.$refs.chat.adjustMessagesScroll()
    },

    fetchChatMessages: async function () {
      await store.dispatch(COMMUNITY_FETCH_CHAT_MESSAGES, { userId: this.communitySelectedChat })
    },

    fetchChatMessagesObs: async function () {
      await store.dispatch(COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS)
    },

    seeChatMessages: async function () {
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES, {
        userId: this.communitySelectedChat,
        messageIds: _.map(this.communityChatMessages, function (o, i) { return o.identifier; })
      });
    },

    seeChatMessagesObs: async function () {
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES_OBS, {
        userId: 'observer',
        messageIds: _.map(this.communityChatMessagesObs, function (o, i) { return o.identifier })
      })
    },

    handleSendMessage: async function (e) {
      if (this.observatorSelected) {
        await store.dispatch(COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS, {
          message: e.message,
          image: e.image,
          file: e.file,
          video: e.video,
        })
        return
      }
      await store.dispatch(COMMUNITY_ADD_CHAT_MESSAGE, {
        message: e.message,
        image: e.image,
        file: e.file,
        video: e.video,
        userId: this.communitySelectedChat,
      })
    },
    async handleDeleteChatMessage(selectedMessages){
      await store.dispatch(COMMUNITY_DELETE_CHAT_MESSAGE, { messageIds: selectedMessages });
    },
    fetchOnlineUsers() {
      for (let c of this.communityChatList) {
        if (c.notSeenCount > 0) {
          this.unseenChatIsEmpty = false
        }
        this.chatOnline({ chat: c, value: this.onlineUsers.testersOnlineCondensed.includes(c.identifier) })
      }
    },
  },
  async mounted() {
    document.getElementsByClassName('ChatPageV2--ChatList')[0].addEventListener('scroll', this.pageScroll)
    await this.fetchChatList("unseen")
    setInterval(() => {
      this.fetchOnlineUsers()
    }, 10000);
    if (this.$route.params.chatSelected) {
      this.order = "all";
      this.isScrollingToChat = true
      await this.fetchChatList(this.order);
      await this.openChat(this.$route.params.chatSelected);
      this.isScrollingToChat = false
      this.$nextTick(() => {
        const selectedUser = document.querySelector('.ChatPageV2--ChatList .CommunityUser.selected');
        if (selectedUser) {
          selectedUser.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    } else {
      this.order = "unseen";
      await this.fetchChatList(this.order);
      this.changeChat(0);
      await this.openChat("OBSERVER");
    }
    setInterval(() => {
      this.fetchOnlineUsers();
    }, 10000);
  },



}
</script>

<style lang="scss">
.ChatPageV2 {
  &--Tabs {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 44px;
    padding: 0 15px !important;
    margin-top: 26px;

    .Tab {
      padding: 10px;
      margin-right: 0 !important;
      font-size: 12px;
      text-transform: uppercase;
      font-family: Roboto Condensed;
      width: 100%;
      text-align: center;

      &--Active {
        background: white;
        font-weight: bold;
        border: 1px solid #E8E8E8;
        border-bottom: none;
        box-shadow: 1px -3px 5px rgba(0, 0, 0, 0.05);
      }
    }
  }

  .CommunityUser {
    background: white;
    border-top: 1px solid #E0E0E0;
    cursor: pointer;
    height: 75px;

    h1 {
      font-size: 15px;
    }

    &--segmentation {
      font-size: 11px;
    }

    &:before {
      top: calc(50% + 10px);
      left: 75px;
      font-size: 14px;
      width: 36px;
    }

    &--image {
      width: 55px;
      min-width: 55px;
      height: 55px;
    }

    &.selected {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }

  &--Chat {
    .ChatVideo {
      position: relative;

      &--Messages {
        height: calc(100vh - 350px);

        @media (max-width: 767px) {
          height: calc(100vh - 340px);
        }
      }
    }
  }

  &--moderatorChat {
    padding: 9px;
    position: relative;
    min-width: 220px;
    border-top: 1px solid #E0E0E0;
    background: white;
    display: flex;

    .Avatar__Background,
    .Avatar {
      width: 43px;
      height: 43px;
    }

    span {
      font-weight: normal;
      font-size: 15px;
      letter-spacing: 0.01em;
      color: #3E3E3E;
      margin-left: 10px;
    }

    &.selected {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }

  &--user .Badge {
    position: absolute;
    top: 23px;
    right: 10px;
    font-size: 15px;
    width: 30px;
    height: 30px;
  }

  &--empty {
    background-color: #ffffff;
    color: #333;
    padding: 30px;
    border: 1px solid #ddd;
    text-align: center;
  }
}
</style>
<style scoped lang="scss">
.ChatPageV2 {
  display: grid;
  grid-template-columns: 330px auto;

  &--user {
    position: relative;
  }

  &--Filter {
    background: #EEEEEE;
  }

  &--Search {
    background: white;
    padding: 15px;

    .SearchBarV2 {
      height: 42px;
    }
  }

  &--ChatList {
    border-bottom: 1px solid #E0E0E0;
    height: calc(100vh - 212px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #e6e6e6;
    }

    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--primary-color);
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    &--Filter {
      order: 1;
    }

    &--ChatList {
      height: calc(100vh - 202px);
    }
  }
}
</style>
