<template>
  <div id="uxVideoInfo">
    <Motion
      class="inner"
      :class="{ rigthInfoHidded: !showAnnotation }"
      :initial="{ opacity: 0, scale: 0 }"
      :animate="{ opacity: 1, scale: 1 }"
      :exit="{ opacity: 0, scale: 0.6 }">
      <div class="close" @click="closeUxVideoInfoModal">
        <IconX />
      </div>
      <div class="leftInfo">
        <div v-if="isActivity" class="taskTitle">{{ activityTitle }}</div>
        <div class="usedTech">
          <div v-if="userNickname" class="device">
            <i class="fas fa-user"></i> 
            <p>{{ userNickname }}</p>
          </div>
          <div v-if="device" class="device">
            <IconDesktop v-if="device == 'DESKTOP'" />
            <IconMobile v-if="device == 'MOBILE'" />
            <p>{{ translateDevice(device) }}</p>
          </div>
          <div class="system">
            <IconWindows v-if="system == 'WINDOWS'" />
            <IconMacOs v-if="system == 'OSX'" />
            <IconLinux v-if="system == 'LINUX'" />
            <IconAndroid v-if="system == 'ANDROID'" />
            <IconMacOs v-if="system == 'IOS'" />
            <p>{{ translateSystem(system) }}</p>
          </div>
          <div class="browser">
            <IconChrome v-if="browser == 'CHROME'" />
            <IconFirefox v-if="browser == 'SAFARI'" />
            <p>{{ translateBrowser(browser) }}</p>
          </div>
        </div>
        <div class="videoDate">
          {{ formatedDate }}
        </div>
        <UxVideoPlayer
          ref="uxVideoPlayer"
          :notes="notes"
          :screenVideoUrl="screenVideoUrl"
          :webcamVideoUrl="webcamVideoUrl"
          :isMobile="device == 'MOBILE'"
          :isIos="system == 'IOS'"
          @toggleShowAnnotation="toggleShowAnnotation"
          @currentTimeChanged="changeCurrentTime"
          @videoPlayed="setAsViewed"
        />
      </div>
      <Transition>
        <div class="rigthInfo" v-show="showAnnotation">
          <UxVideoInfoSideBar
            :currentTime="currentTime"
            :notes="notes"
            :device="translateDevice(device)"
            :browser="translateBrowser(browser)"
            :transcription="props.transcription"
            :status="isActivity ? props.status: null"
            :activityType="isActivity ? activityType : null"
            @goToSecond="goToSecond"
          />
        </div>
      </Transition>
    </Motion>
  </div>
</template>
<script setup>
import { inject, ref, onBeforeMount } from 'vue'
import ApiService from '../../../services/api.service'

//import { Motion } from 'motion/vue'
import moment from 'moment-timezone'

import IconDesktop from '../icons/IconDesktop.vue'
import IconMobile from '../icons/IconMobile.vue'
import IconWindows from '../icons/IconWindows.vue'
import IconMacOs from '../icons/IconMacOS.vue'
import IconLinux from '../icons/IconLinux.vue'
import IconAndroid from '../icons/IconAndroid.vue'
import IconChrome from '../icons/IconChrome.vue'
import IconFirefox from '../icons/IconFirefox.vue'
import IconX from '../icons/IconX.vue'

import UxVideoPlayer from './UxVideoPlayer.vue'
import UxVideoInfoSideBar from './UxVideoInfoSideBar.vue'
import mitt from 'mitt'
const props = defineProps({
  activityTitle: String,
  testId: Number,
  activityType:String,
  isActivity: {
    type: Boolean,
    default: false,
  },
  taskNum: Number,
  videoDate: {
    type: String,
    default: null,
  },
  device: {
    type: String,
    default: '',
    validator(value) {
      return [
        'DESKTOP',
        'MOBILE',
        'TABLET',
        'FULLDESKTOP',
        'FULLMOBILE',
        'FULLTABLET',
        'UNKNOWN',
      ].includes(value)
    },
  },
  system: {
    type: String,
    default: '',
    validator(value) {
      return [
        'WINDOWS',
        'OSX',
        'LINUX',
        'ANDROID',
        'IOS',
        'OTHER',
        'UNKNOWN',
      ].includes(value)
    },
  },
  browser: {
    type: String,
    default: 'CHROME',
  },
  screenVideoUrl: {
    type: String,
    default: '',
  },
  webcamVideoUrl: {
    type: String,
    default: '',
  },
  executionId: {
    type: String,
    default: '',
  },
  transcription: {
    type: Array,
    default: null,
  },
  status: String,
  userNickname: String,
})
const emitter = inject('emitter')

const showAnnotation = ref(true)
const currentTime = ref(0)
const uxVideoPlayer = ref(null)
const formatedDate = ref('')

const formatDate = (date) => {
  moment.locale('es')
  if (date == undefined) return ''
  return moment(date).format('DD/MM/YYYY - HH:MM')
}
onBeforeMount(() => {
  formatedDate.value = formatDate(props.videoDate)
})
const closeUxVideoInfoModal = () => {
  emitter.emit('closeUxVideoInfoModal')
}
const changeCurrentTime = (time) => {
  currentTime.value = time
}
const goToSecond = (second) => {
  uxVideoPlayer.value.goToSecond(second)
}
const toggleShowAnnotation = (value) => {
  showAnnotation.value = value
}
const setAsViewed = async () => {
  await ApiService.post(
    `/clientDashboard/test/${props.testId}/task/${props.taskNum}/execution/${props.executionId}/viewed`
  )
}
const translateDevice = (device) => {
  switch (device) {
    case 'DESKTOP':
      return 'Desktop'

    case 'FULLDESKTOP':
      return 'Desktop'

    case 'MOBILE':
      return 'Mobile'

    case 'FULLMOBILE':
      return 'Mobile'

    case 'TABLET':
      return 'Tablet'

    case 'FULLTABLET':
      return 'Tablet'

    default:
      return 'Otro'
  }
}
const translateSystem = (system) => {
  switch (system) {
    case 'IOS':
      return 'iOS'
    case 'ANDROID':
      return 'Android'
    case 'WINDOWS':
      return 'Windows'
    case 'LINUX':
      return 'Linux'
    case 'OSX':
      return 'MacOS'
    case 'OSX':
      return 'MacOS'

    default:
      return 'Otro'
  }
}
const translateBrowser = (browser) => {
  switch (browser) {
    case 'IE':
      return 'IExplorer'
    case 'MOBILEIE':
      return 'Mobileie'
    case 'MOZILLA':
      return 'Firefox'
    case 'MOBILEMOZILLA':
      return 'Firefox'
    case 'CHROME':
      return 'Chrome'
    case 'MOBILECHROME':
      return 'Chrome'
    case 'SAFARI':
      return 'Safari'
    case 'MOBILESAFARI':
      return 'Safari'
    case 'OPERA':
      return 'Opera'
    case 'MOBILEOPERA':
      return 'Opera'
    case 'EDGE':
      return 'Edge'
    case 'APP':
      return 'App'

    default:
      return 'Otro'
  }
}
const notes = [
  {
    note: 'El usuario pudo navegar por la página y encontrar la información requerida sin mayores dificultades. Esto sugiere que la navegación en la página es intuitiva y fácil de seguir.',
    second: 1.481589,
  },
  {
    note: 'El usuario pudo navegar por la página y encontrar la información requerida sin mayores dificultades. Esto sugiere que la navegación en la página es intuitiva y fácil de seguir.',
    second: 3.481589,
  },
  {
    note: 'El usuario pudo navegar por la página y encontrar la información requerida sin mayores dificultades. Esto sugiere que la navegación en la página es intuitiva y fácil de seguir.',
    second: 6.481589,
  },
  {
    note: 'El usuario pudo navegar por la página y encontrar la información requerida sin mayores dificultades. Esto sugiere que la navegación en la página es intuitiva y fácil de seguir.',
    second: 9.481589,
  },
  {
    note: 'El usuario pudo navegar por la página y encontrar la información requerida sin mayores dificultades. Esto sugiere que la navegación en la página es intuitiva y fácil de seguir.',
    second: 12.481589,
  },
]
</script>

<style lang="scss" scoped>
#uxVideoInfo {
  position: absolute;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px 0px;
  * {
    text-align: initial;
  }
  .inner {
    flex-grow: 1;
    background-color: white;
    width: 95%;
    //provisional:
    /* width: 70%; */
    height: fit-content;
    border-radius: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
    //provisional:
    /* grid-template-columns: 100% 0%; */
    transition: grid-template-columns 0.5s;

    &.rigthInfoHidded {
      grid-template-columns: 100% 0%;
    }
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      padding: 20px;
      width: fit-content;
      height: fit-content;
      border-radius: 50%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
      position: absolute;
      top: -20px;
      right: -20px;
      cursor: pointer;
    }
    .leftInfo {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: fit-content;
      padding: 30px 30px;
      .taskTitle {
        font-family: 'Bariol';
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        color: #1f1f1f;
      }
      .usedTech {
        display: flex;
        flex-direction: row;
        gap: 20px;
        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          border: 1.46099px solid #eaeaea;
          border-radius: 14608.4px;
          padding: 4px 11px;
          > p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 18.9928px;
          }
          > svg {
            transform: scale(1.5);
          }
          > i {
            color:#8134FF;
          }
        }
      }
    }
    .rigthInfo {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
