<template>
  <div id="uxVideoPrevInfoBox">
    <div class="top" :style="imageInlineStyles">
      <div class="seen" v-if="isVideoSeen">
        <IconEye />
        <p>{{$t('watmonitor_seen')}}</p>
      </div>
      <!-- <div class="duration">
        <IconClock />
        <p>{{ videoDuration }}</p>
      </div> -->
      <div class="playButton" @click="openUxVideoInfoModal">
        <div class="icon">
          <IconPlayButton />
        </div>
        <p v-show="expandPlayButton">{{ $t('watmonitor_See_info') }}</p>
      </div>
    </div>
    <div class="bottom">
      <div v-if="userNickname" class="userInfo">
        {{ userNickname }}
      </div>
      <div class="usedTech videoDate">
        <div  v-if="device" class="device">
          <IconDesktop v-if="device == 'DESKTOP'" />
          <IconMobile v-if="device == 'MOBILE'" />
          <p>{{ translateDevice(device) }}</p>
        </div>
        <div class="system">
          <IconWindows v-if="system == 'WINDOWS'" />
          <IconMacOs v-if="system == 'OSX'" />
          <IconLinux v-if="system == 'LINUX'" />
          <IconAndroid v-if="system == 'ANDROID'" />
          <IconMacOs v-if="system == 'IOS'" />
          <p>{{ translateSystem(system) }}</p>
        </div>
        <div class="browser">
          <IconChrome v-if="browser == 'CHROME'" />
          <IconFirefox v-if="browser == 'SAFARI'" />
          <p>{{ translateBrowser(browser) }}</p>
        </div>
      </div>
      <div class="videoDate">
        {{ formatedDate }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onBeforeMount, computed, onMounted } from 'vue'
import ApiService from '../../../services/api.service'
import moment from 'moment-timezone';


import IconEye from '../icons/IconEye.vue'
import IconClock from '../icons/IconClock.vue'
import IconPlayButton from '../icons/IconPlayButton.vue'
import { animate } from 'motion'
/* import { reactiveStyle } from "@vueuse/motion"; */
import vueApp from '@/main'
import { mount } from '@/utils/mount'
import UxVideoInfo from './UxVideoInfo.vue'
import IconDesktop from '../icons/IconDesktop.vue'
import IconMobile from '../icons/IconMobile.vue'
import IconWindows from '../icons/IconWindows.vue'
import IconMacOs from '../icons/IconMacOS.vue'
import IconLinux from '../icons/IconLinux.vue'
import IconAndroid from '../icons/IconAndroid.vue'
import IconChrome from '../icons/IconChrome.vue'
import IconFirefox from '../icons/IconFirefox.vue'

const props = defineProps({
  testId: Number,
  activityTitle: String,
  activityType:{
    type: String,
    default: 'VIDEOCHAT',
  },
  isActivity: {
    type: Boolean,
    default: false,
  },
  communityTranscriptions: Array,
  taskNum: Number,
  videoImageUrl: {
    type: String,
    default:
      'https://plus.unsplash.com/premium_photo-1683309558738-aa188bd24140?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
  },
  isVideoSeen: {
    type: Boolean,
    default: true,
  },
  // videoDuration: {
  //   type: String,
  //   default: '00:00',
  // },
  videoDate: {
    type: String,
  },
  device: {
    type: String,
    default: '',
    validator(value) {
      return [
        'DESKTOP',
        'MOBILE',
        'TABLET',
        'FULLDESKTOP',
        'FULLMOBILE',
        'FULLTABLET',
        'UNKNOWN',
      ].includes(value)
    },
  },
  system: {
    type: String,
    default: 'WINDOWS',
    validator(value) {
      return [
        'WINDOWS',
        'OSX',
        'LINUX',
        'ANDROID',
        'IOS',
        'OTHER',
        'UNKNOWN',
      ].includes(value)
    },
  },
  browser: {
    type: String,
    default: 'CHROME',
  },
  screenVideoUrl: {
    type: String,
    default: '',
  },
  webcamVideoUrl: {
    type: String,
    default: '',
  },
  executionId: {
    type: String,
    default: '',
  },
  transcription: {
    type: Array,
    default: null,
  },
  userNickname:{
    type: String,
    default: '',
  }
})
const emit = defineEmits(['setUxVideoInfoInstance'])

const expandPlayButton = ref(false)
const videoThumbSrc = ref('')
const formatedDate = ref('')
const imageInlineStyles = computed(() => {
  return {
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 44.28%, rgba(0, 0, 0, 0.9) 100%), url(${videoThumbSrc.value})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
})
    const getTranscription = (screenVideoUrl) => {
  if (!props.communityTranscriptions?.length) {
    return null;
  }
  const foundTranscription = props.communityTranscriptions?.find(transcription => transcription.originalVideoUrl === screenVideoUrl);
  return foundTranscription ? foundTranscription.transcriptionLines : '';
};
    const getTranscriptionStatus = (screenVideoUrl) => {
  if (!props.communityTranscriptions?.length) {
    return null;
  }
  const status = props.communityTranscriptions?.find(transcription => transcription.originalVideoUrl === screenVideoUrl);
  return status ? status.status : null;
};

const generateVideoThumbSrc = (videoUrl) => {
  videoThumbSrc.value = videoUrl
    .replace(
      'wat-videos.s3.amazonaws.com',
      's3-eu-west-1.amazonaws.com/wat-thumbs'
    )
    .replace('.mp4', '00001.png')
}

const formatDate = (date) => {
  moment.locale('es');
  if(date == undefined) return ""
  return moment(date).format('DD/MM/YYYY - HH:MM');
}

onBeforeMount(async () => {
  formatedDate.value = formatDate(props.videoDate)
  generateVideoThumbSrc(
    props.webcamVideoUrl !== '' ? props.webcamVideoUrl : props.screenVideoUrl
  )
})

const openUxVideoInfoModal = () => {
  const urlForTranscription = props.screenVideoUrl || props.webcamVideoUrl;
  const transcription = props.isActivity
    ? getTranscription(urlForTranscription)
    : props.transcription;
  const status = props.isActivity
    ? getTranscriptionStatus(urlForTranscription)
    : null;
    
  const { el, vNode, destroy } = mount(UxVideoInfo, {
    props: {
      videoDate: props.videoDate,
      device: props.device,
      system: props.system,
      browser: props.browser,
      screenVideoUrl: props.screenVideoUrl,
      webcamVideoUrl: props.webcamVideoUrl,
      testId: props.testId,
      taskNum: props.taskNum,
      executionId: props.executionId,
      transcription: transcription,
      status: status,
      activityType: props.activityType,
      activityTitle:props.isActivity ? props.activityTitle : null,
      isActivity: props.isActivity,
      userNickname: props.userNickname,
      
    },
    app: vueApp,
  })
  vueApp._container.appendChild(el)
  emit('setUxVideoInfoInstance', {
    el: el,
    vNode: vNode,
    destroy: destroy,
  })
}
const translateDevice = (device) => {
  switch (device) {
    case 'DESKTOP':
      return 'Desktop'

    case 'FULLDESKTOP':
      return 'Desktop'

    case 'MOBILE':
      return 'Mobile'

    case 'FULLMOBILE':
      return 'Mobile'

    case 'TABLET':
      return 'Tablet'

    case 'FULLTABLET':
      return 'Tablet'

    default:
      return 'Otro'
  }
}
const translateSystem = (system) => {
  switch (system) {
    case 'IOS':
      return 'iOS'
    case 'ANDROID':
      return 'Android'
    case 'WINDOWS':
      return 'Windows'
    case 'LINUX':
      return 'Linux'
    case 'OSX':
      return 'MacOS'
    case 'OSX':
      return 'MacOS'

    default:
      return 'Otro'
  }
}
const translateBrowser = (browser) => {
  switch (browser) {
    case 'IE':
      return 'IExplorer'
    case 'MOBILEIE':
      return 'Mobileie'
    case 'MOZILLA':
      return 'Firefox'
    case 'MOBILEMOZILLA':
      return 'Firefox'
    case 'CHROME':
      return 'Chrome'
    case 'MOBILECHROME':
      return 'Chrome'
    case 'SAFARI':
      return 'Safari'
    case 'MOBILESAFARI':
      return 'Safari'
    case 'OPERA':
      return 'Opera'
    case 'MOBILEOPERA':
      return 'Opera'
    case 'EDGE':
      return 'Edge'
    case 'APP':
      return 'App'

    default:
      return 'Otro'
  }
}
</script>
<style lang="scss" scoped>
#uxVideoPrevInfoBox {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: fit-content;
  .top {
    width: 260px;
    height: 145px;
    border-radius: 4px;
    position: relative;
    .seen {
      position: absolute;
      top: 12px;
      left: 10px;
      border-radius: 20px;
      padding: 2px 6px;
      background-color: #8134ff;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      width: fit-content;
      color: white;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
    }
    .duration {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 13px;
      left: 13px;
      color: white;
      gap: 2px;
      > p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
      }
    }
    .playButton {
      width: fit-content;
      position: absolute;
      bottom: 13px;
      right: 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      background-color: #96c800;
      border-radius: 50%;
      padding: 12px;
      cursor: pointer;
      .icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > p {
        position: absolute;
        right: 10px;
        color: white;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .userInfo{
      padding: 0 8px;
    }
    .usedTech {
      display: flex;
      flex-direction: row;
      gap: 4px;
      > div {
        padding: 3px 8px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #000000;
      }
    }
    .videoDate {
      font-family: 'Lato';
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      color: #626262;
    }
  }
}
</style>
