
import { defineComponent } from 'vue';
import SwitchInput from '../SwitchInput/SwitchInput.vue'
import { isQuestionWithValues } from '../../utils/survey-creation.ts'
import Dropzone from '../Dropzone';

export default defineComponent({
  name : 'QuestionConfig',
  components:{
    SwitchInput,
    Dropzone
  },
  props:{
    question: {
      type: Object,
      default: {images:[]}
    },
    filePath: String,
    videoPath: String,
    headers: Object
  },
  methods:{
    isQuestionRandom(){
      return isQuestionWithValues(this.question.type) && this.question.type != "DIVIDE_POINTS"
    },
    isTextQuestion(){
      return this.question.type == 'TEXT' || this.question.type == 'MULTITEXT' || this.question.type == 'TEXTAREA'
    },
    imageUploadSuccess(file: any, response: any){
      if(this.question.images == undefined || this.question.images == null) this.question.images = []
      this.question.images.push(response.path)
      const uploader: any = this.$refs.imageUploader
      uploader.reset()
      this.question.imageDisplayType = 'IMAGE_DISPLAY_MINIATURE'
    },
    videoUploadSuccess(file: any, response: any){
      this.question.video = response.path
      const uploader: any = this.$refs.videoUploader
      uploader.reset()
    },
    deleteImage(i: any){
      this.question.images.splice(i,1)
    },
    deleteVideo(i: any){
      this.question.video = undefined
    },
  }
})
