<template lang="html">
  <div class="CommentV2" :class="'comment_'+comment.identifier,{'CommentV2--Mentioned':mentionedClass}">
    <div class="CommentV2--Info" :class="{ 'disabled': !allowAddMedals || comment.moderator}">
      <avatar
        class="CommentV2__UserAvatar"
        :class="{'hideGamification':hideGamification || comment.moderator}"
        :src="comment.image"
        :labs="comment.testerPoints"
        :allowLabs="!hideGamification && !comment.moderator"
        :online="comment.online"
        @mouseover="comment.moderator ? null : getMemberInfoBefore(comment)" 
        >
      </avatar>
      <span class="CommentV2__PublishDate">{{ $filters.formatDate(comment.creationDate) }}</span>
      <div class="AvatarPanel" 
        v-if="showAvatarPanel"
        @mouseleave="showAvatarPanel = false"
        >
        <div class="AvatarPanel__Content">
          <div class="AvatarPanel__Header">
            <avatar
              class="CommentV2__UserAvatar"
              :class="{'hideGamification':hideGamification || comment.moderator}"
              :src="comment.image"
              :labs="comment.testerPoints"
              :allowLabs="!hideGamification && !comment.moderator"
              :online="comment.online"
              >
            </avatar>
            <div class="AvatarPanel__Header__UserData">
              <h1>{{ comment.moderator ? $t('role_moderator') : comment.nickname }}</h1>
              <div class="AvatarPanel__Header__UserData__Detail">
                <p v-if="memberData.gender">{{ $t("gender_" + memberData.gender) }} | </p> 
                <p v-if="memberData.age">{{ $t("age", { count: memberData.age }) }} | </p>
                <p v-if="province">{{ $t(''+province) }}</p>
              </div>
            </div>
          </div>
          <div class="AvatarPanel__Body">
            <div class="AvatarPanel__Tags" v-if="memberData.tags || memberData.publicTags">
              <Tag :name="t" class="TagV2" v-for="t in memberData.tags" /> 
              <Tag :name="t" class="TagV2" v-for="t in memberData.publicTags" /> 
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="LoadingIcon">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </div>
    <div class="CommentV2__Content">
      <h1>{{ comment.moderator ? $t('role_moderator') : comment.nickname }}
        <span v-if="comment.topicId != undefined" :style="{'background':getDebateTopic(comment.topicId,'COLOR')}">{{getDebateTopic(comment.topicId,"TAG")}}</span>
        <span class="CommentV2--New" v-if="allowUnseenCheck && !comment.moderatorSeen && !comment.moderator">
            <i class="far fa-star"></i> <b>{{$t('chat_filter_new')}}</b>
        </span>
        <span v-if="comment.activityTitle" class="CommentV2__Activity"> | {{$t('activity')}}: <i>{{comment.activityTitle}} (id: {{ comment.activityId }}) |</i></span>
        <span v-if="allowCommentLink" class="CommentV2__Link" v-on:click="$emit('goActivity', comment)"> <i class="fas fa-external-link-alt"></i>{{$t('activity_go_link')}}</span>
      </h1>
      <div class="CommentV2__Content--Center" ref="commentContentCenter">
        <div class="CommentV2--Text">
          <p v-html="truncatedText"></p>
          <button class="LeerMas--Button" v-if="showToggleButton" @click="toggleContent">{{ showFullContent ? "Leer menos" : "Leer más" }}</button>
          <div class="CommentV2__Media" v-if="comment.images || comment.videoDatas">
            <MediaViewer :images="formatMedia(comment.images)" :videos="formatVideo(comment.videoDatas)" />
          </div>
          <div class="CommentV2__Files" v-if="comment.files">
            <div class="CommentV2__Files--File" v-for="file in comment.files">
              <a :href="file"><i class="fa fa-paperclip"></i>{{$t('comment_download_subject')}}</a>
            </div>
          </div>
        </div>
        <div class="CommentV2--ReactionHands ReadOnly" v-if="hasReactions()">
          <div class="Reaction--Like">
            <i class="fas fa-thumbs-up"></i>
            {{ likeCount }}
          </div>
          <div class="Reaction--Dislike">
            <i class="fas fa-thumbs-down"></i>
            {{ dislikeCount }}
          </div>
        </div>
        <div class="CommentV2--Medal" v-if="allowAddMedals && !comment.moderator && !hideGamification">
          <img src="../../assets/img/goldMedal.png" v-if="comment.medal == 'GOLD'" class="active">
          <img src="../../assets/img/goldMedal.png" v-if="comment.medal != 'GOLD'" v-on:click="$emit('comment-medal', { commentId: comment.identifier, medal:'GOLD' })">
        </div>
        <i v-if="allowMenu" v-on:click="showActions = !showActions" class="fas fa-ellipsis-v"></i>
        <div class="CommentV2--MenuActions" v-if="showActions">
          <p v-if="allowChatButton" v-on:click="$emit('open-chat', { memberId: comment.ownerId })"><i class="far fa-comments"></i> {{$t('chat')}}</p>
          <p v-if="allowPinComment" @click="$emit('pin-comment', {
            commentId : comment.identifier,
            text      : comment.text,
            tags      : comment.moderatorTags
          })"><i class="fas fa-thumbtack"></i> {{ comment.isPinned ? 'Quitar Destacado' : 'Destacar'}}</p>
        </div>
      </div>
      <div class="CommentV2--Actions">
        <p class="response" v-if="(allowReply || allowReplyParent) && !forExporting && !isObservator" v-on:click="replyComment()">{{$t('comment_response')}}</p>
        <p class="delete" v-if="allowDelete" v-on:click="$emit('comment-delete', { commentId: comment.identifier })">{{$t('action_delete')}}</p>
        <p class="tags" v-if="allowTag && !forExporting" v-on:click="$emit('comment-tags', { comment: comment })">{{$t('comment_note_tags')}}</p>
        <div class="likeButtons" v-if="(allowLike || allowDislike) && !comment.moderator">
          <div
            v-if="allowLike && !comment.moderator"
            class="Reaction Reaction--Like"
            :class="{ 'Reaction--Like--Selected': comment.testerReaction == COMMENT_REACTION_LIKE }">
            <div class="tooltip-container">
              <icon-button
                class="Reaction__Button"
                :style="{color: colorSelection(comment,'LIKE')}"
                icon="thumbs-up"
                v-on:click="handleCommentReaction(comment.identifier, comment.testerReaction, COMMENT_REACTION_LIKE)"
                @mouseover="showTooltip = true"
                @mouseleave="showTooltip = false"
              ></icon-button>
              <div class="tooltip" v-if="showTooltip && allowTag">{{ $t('tooltip_like_dislike_for_moderator') }}</div>
            </div>
            <span class="Reaction__Counter">{{ comment.totalLikes > 0 ? comment.totalLikes : '' }}</span>
          </div>
          <div
            v-if="allowDislike && !comment.moderator"
            class="Reaction Reaction--Dislike"
            :class="{ 'Reaction--Dislike--Selected': comment.testerReaction == COMMENT_REACTION_DISLIKE }">
            <div class="tooltip-container">
              <icon-button
                class="Reaction__Button"
                :style="{color: colorSelection(comment,'DISLIKE')}"
                icon="thumbs-down"
                v-on:click="handleCommentReaction(comment.identifier, comment.testerReaction, COMMENT_REACTION_DISLIKE)"
                @mouseover="showTooltip = true"
                @mouseleave="showTooltip = false"
              ></icon-button>
              <div class="tooltip" v-if="showTooltip && allowTag">{{ $t('tooltip_like_dislike_for_moderator') }}</div>
            </div>
            <span class="Reaction__Counter">{{ comment.totalDisLikes > 0 ? comment.totalDisLikes : '' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex'
import Avatar from '../Avatar'
import Button from '../Button'
import Icon from '../Icon'
import Tag from '../Tag';
import IconButton from '../IconButton'
import MediaViewer from '../MediaViewer'
import moment from 'moment-timezone';
import store from '@/store';
import { FETCH_MEMBER_INFO_BY_NICKNAME } from "@/store/community/members.module";

import {
  COMMENT_REACTION_NONE,
  COMMENT_REACTION_LIKE,
  COMMENT_REACTION_DISLIKE,
} from '../../constants/constants.type'
import { parseVideoUrl } from '../../utils/parse-video-url'
import { map } from 'lodash'

export default defineComponent({
  name: 'CommentV2',

  props: {
    comment: {
      type: Object,
    },

    allowLike: {
      type: Boolean,
      default: true,
    },

    allowDislike: {
      type: Boolean,
      default: true,
    },

    allowReply: {
      type: Boolean,
      default: true,
    },

    allowReplyParent: {
      type: Boolean,
      default: false,
    },

    allowDelete: {
      type: Boolean,
      default: false,
    },

    allowTag: {
      type: Boolean,
      default: false,
    },

    allowAddMedals: {
      type: Boolean,
      default: false,
    },

    allowPinComment: {
      type: Boolean,
      default: false,
    },

    allowChatButton: {
      type: Boolean,
      default: false,
    },

    allowCommentLink: {
      type: Boolean,
      default: false,
    },

    allowUnseenCheck:{
      type: Boolean,
      default: false,
    },

    hideGamification: {
      type: Boolean,
      default: false,
    },

    forExporting:{
      type: Boolean,
      default: false,
    },
    debateTopics: {
      type: Array,
      default:null,
    },
    isObservator:{
      type:Boolean,
      default:false,
    },
    allowMenu:{
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object
    },
    savedMembers: {
      type: Array,
      required: true
    },
    fromSearchPage: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Avatar,
    'w-button': Button,
    Icon,
    Tag,
    IconButton,
    MediaViewer,
  },

  mounted(){
     // Verificar si el texto es mayor al límite inicialmente
     if (this.commentText.length > this.maxLength) {
      this.showFullContent = false; // Asegurar que el contenido esté truncado al principio
    } else {
      this.showFullContent = true; // Mostrar el contenido completo si es menor al límite
    }
  },

  computed:{
    ...mapGetters([
      'userProfile'
    ]),
    commentText(){
      const styles = 'color:#00acee;'

      let comment = this.comment.text.replace(/<span/g, "<span style='"+styles+"'");
      const mentionCount = (comment.match(new RegExp('data-type="mention"', 'g')) || []).length
      for(let n = 0; n<mentionCount;n++){
        const jsonOpen = comment.indexOf('{')
        const jsonClose = comment.indexOf('}')+1
        const jsonMention = comment.substring(jsonOpen,jsonClose)
        const mentionStart = comment.indexOf('<span');
        const mentionEnd = comment.indexOf('</span>') + '</span>'.length;
        if(jsonMention.length > 0){
          try {
            const jsonParsed = JSON.parse(jsonMention)
            comment = comment.replace(jsonMention, '@' + jsonParsed.nick)
          } catch(error) {
            if (mentionStart !== -1 && mentionEnd !== -1) {
              const mentionText = comment.substring(mentionStart, mentionEnd);
              const mentionTextWithoutTags = mentionText.replace(/<\/?[^>]+(>|$)/g, "");
              comment = comment.replace(mentionText, '@' + mentionTextWithoutTags);
            }
          }
        }
      }
      return comment
    },
    mentionedClass(){
      if(this.comment.mentionedUserIds == undefined) return false
      return this.comment.mentionedUserIds[this.userProfile.id] == false
    },
    truncatedText() {
      return this.showFullContent ?  this.commentText : this.commentText.slice(0, this.maxLength) + '...' ;
    },
    showToggleButton() {
      return this.commentText.length > this.maxLength;
    },
    
    likeCount() {
      if (!this.comment.reactions) return 0;
      return Object.values(this.comment.reactions).filter(val => val === "LIKE").length;
    },
    dislikeCount() {
      if (!this.comment.reactions) return 0;
      return Object.values(this.comment.reactions).filter(val => val === "DISLIKE").length;
    },
  },

  filters: {

    embedLink: function (url) {
      return parseVideoUrl(url).embedLink
    },

    formatMedia: function (media) {
      return map(media, (path) => {
        return { src: path }
      })
    },

    formatVideo: function (video){
      return map(video, (v) => {
        return { src: v.videoUrl }
      })
    }
  },

  data() {
    return {
      COMMENT_REACTION_NONE: COMMENT_REACTION_NONE,
      COMMENT_REACTION_LIKE: COMMENT_REACTION_LIKE,
      COMMENT_REACTION_DISLIKE: COMMENT_REACTION_DISLIKE,  
      FETCH_MEMBER_INFO_BY_NICKNAME: FETCH_MEMBER_INFO_BY_NICKNAME,
      showAvatarPanel: false,
      memberData: [],
      buttonDisabled: false,
      showTooltip: false,
      loading: false,
      province: "",
      maxLength: 1000, 
      showFullContent: false,
      nickname: ''

    }
  },

  methods: {
    getMemberInfoBefore(member){
      const memberId = member.nickname;
      const memberIds = this.savedMembers.map(member => member.nickname);
      if (memberIds.includes(memberId)) {
        const objetoEncontrado = this.savedMembers.find(member => member.nickname === memberId);
        this.memberData.gender = objetoEncontrado.gender;
        this.memberData.age = objetoEncontrado.age;
        this.province = objetoEncontrado.province;
        this.memberData.tags = objetoEncontrado.tags;
        this.memberData.publicTags = objetoEncontrado.publicTags;
        this.showAvatarPanel = true;
      } else {
        this.getMemberInfo(member);
      }
    },
    async getMemberInfo(member){
      if (this.allowAddMedals) {
        if (this.memberData == null || this.memberData == "" || this.memberData == undefined) {
          this.loading = true
        } else {
          this.loading = false
        }
        await store
          .dispatch(FETCH_MEMBER_INFO_BY_NICKNAME, {
            nickname: member.nickname
          })
          .then((data) => {
            this.showAvatarPanel = true
            this.memberData = data
            this.province = this.memberData.province.text
          })
          .finally(() => {
            this.loading = false;
            this.$emit('addMember', this.memberData);
          });
      }
    },
    getDebateTopic(id,type){
      if(this.debateTopics == null) return null
      for( let dt of this.debateTopics ){
        if(id == dt.topicId && type == "TAG") return dt.topicTagText
        if(id == dt.topicId && type == "COLOR") return dt.topicTagColor
      }
    },
    replyComment() {
  this.$emit('comment-reply', { 
    commentId: this.allowReplyParent ? this.comment.parentCommentId : this.comment.identifier,
    nickname: this.comment.moderator ? this.$t('role_moderator') : this.comment.nickname,
    last: this.allowReplyParent,
    isModerator: this.comment.moderator
  });
},
    handleCommentReaction: function (commentId, currentReaction, newReaction) {
      this.$emit('comment-reaction', {
        commentId: commentId,
        currentReaction: currentReaction,
        newReaction: newReaction,
      })
    },

    allowTagsPreview: function(comment){
        if (this.allowTag && comment.moderatorNote != undefined || comment.moderatorTags != undefined) {
          return true
        }
        else{
          return false
        }
    },

    hasTags: function(comment){
      if(comment.moderatorTags == undefined){
        return false
      }
      else if(comment.moderatorTags.length > 0){
        return true
      }
      else{
        return false
      }
    },

    colorSelection: function(comment, reaction){
      if (reaction == "LIKE") {
        if (this.theme.mainColor && comment.testerReaction == COMMENT_REACTION_LIKE) {
          return this.theme.mainColor
        }
      }
      if (reaction == "DISLIKE") {
        if (this.theme.mainColor && comment.testerReaction == COMMENT_REACTION_DISLIKE) {
          return this.theme.mainColor
        }
      }
      return
    },

    embedLink: function (url) {
      return parseVideoUrl(url).embedLink
    },

    formatMedia: function (media) {
      return map(media, (path) => {
        return { src: path }
      })
    },

    formatVideo: function (video){
      return map(video, (v) => {
        return { src: v.videoUrl }
      })
    },

    toggleContent(){
      this.showFullContent = !this.showFullContent
    },
    hasReactions() {
      return this.comment.reactions && Object.keys(this.comment.reactions).length > 0 && this.fromSearchPage;
    },
  },
  setup() {
    const commentContentCenter = ref(null)
    const showActions = ref(false)
    onClickOutside(commentContentCenter, (event) => showActions.value = false)
    return { commentContentCenter, showActions }
  }
})
</script>

<style lang="scss">
  .CommentV2{
  .Avatar.hideGamification .Avatar__Background{
    width: 100%;
  }
  .Avatar__Background{
    width: 49px;
    height: 50px;
  }
  .Avatar__Points {
    width: 49px !important;
    min-width: unset !important;
  }
  .Avatar__Points--Num {
    height: 33px;
    font-size: 1rem;
  }
  .Avatar__Points--Text {
    height: 15px;
    font-size: 0.6rem;
  }
}
</style>
<style lang="scss" scoped>
.CommentV2{
  display: flex;
  width: 100%;
  overflow-wrap: anywhere;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
      }
  &--Mentioned{
    border: 1px solid var(--primary-color);
    position: relative;
    &:before{
      content: "@";
      color: var(--primary-color);
      position: absolute;
      top: 5px;
      right: 5px;
      background: white;
      border: 1px solid lightgray;
      border-radius: 50%;
      padding: 5px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px lightgrey;
      line-height: 1px;
    }
  }
  &__Activity{
    cursor: pointer;
    color: black!important;
    background: white!important;
    padding: 0!important;
    margin: 0!important;
  }
  &__Link{
    cursor: pointer;
    color: var(--primary-color)!important;
    background: white!important;
    padding: 0!important;
    margin: 0!important;
  }
  &__Media{
    margin-top: 5px;
    .MediaViewer{
      max-width: 50%;
    }
  }
  &--Info{
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    position: relative;
    align-items: flex-start;
    &:hover {
    }
  }
  &--Info.disabled {
    pointer-events: none;
  }
  &__UserAvatar {
    flex-shrink: 0;
    width: 100px !important;
    height: 50px !important;
    box-shadow: none !important;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
      margin-right: .5em;
    }
  }
  &__PublishDate {
    width: 100%;
    font-size: 13px;
    background: #f2f2f2;
    color: #898989;
    text-align: right;
    padding: 3px;
    position: relative;
  }
  &__Content{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    h1{
      font-size: 19px;
      span{
        display: inline-flex;
        align-items: center;
        background-color: #eee;
        line-height: 1.5em;
        padding: 0 .6em;
        border-radius: 20px;
        font-size: 12.8px;
        margin-left: 10px;
        color: white;
      }
    }
    &--Center{
      display: flex;
      justify-content: space-between;
      margin: 5px 0 10px 0;
      position: relative;
      i{
        color: rgb(191,191,191);
      }
    }
  }
  &--Text{
    color: #696969;
    font-size:16px;
    width: 100%;
    p{
      white-space: pre-wrap;
      span{
        color: var(--primary-color);
      }
    }
    .LeerMas--Button{
      background: none;
      border: none;
      color: blue;
      cursor: pointer;
    }
  }
  &--New{
    font-size:12px;
    margin-left: 20px;
    display: inline-flex;
    align-items: center;
    background-color: #eee;
    line-height: 1.5em;
    padding: 0 .6em;
    border-radius: 20px;
    overflow: hidden;
    color: black!important;
    i{
      color: var(--primary-color);
      margin-right: 5px;
    }
  }
  &--Medal{
    cursor: pointer;
    margin:0 5px;
    img{
      width: 20px;
      opacity: 0.5;
      &.active{
        opacity:1;
      }
    }
  }
  &--ReactionHands{
    display: flex;
    gap: 8px; 
    align-items: baseline;
      .Reaction--Like,
      .Reaction--Dislike {
        display: flex;
        font-size: 14px; 
        color: #666;
    
        i {
          margin-right: 6px; 
          color: #999; 
        }
      }
  }
  &--Actions{
    display: flex;
    p{
      margin-right: 10px;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      &.response{
        color: rgb(99,155,224);
      }
      &.delete{
        color: rgb(229,68,74);
      }
      &.tags{
        color: rgb(211,190,46);
      }
    }
  }
  &--MenuActions{
    position: absolute;
    background: white;
    border: 1px solid #f2f2f2;
    border-bottom: none;
    right: 15px;
    top: 0;
    &:before{
      content: "";
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      border-left: 8px solid #f2f2f2;
      position: absolute;
      right: -8px;
    }
    p{
      border-bottom: 1px solid #f2f2f2;
      padding: 5px 10px;
      text-align: left;
      cursor: pointer;
      &:hover{
        color: #898989;
      }
    }
  }
}
.likeButtons{
  display: flex;
  margin-top: -10px;
}
.Reaction {
  &__Button {
    :active {
      background-color: #EEE;

      .Icon {
        transform: scale(1.3);
      }
    }
  }

  &--Like {
    margin-right: 5px;
  }

  &--Like,
  &--Dislike,
  &--Reply {
    display:flex;
    align-items: center;
    .Reaction__Button {
      color: #BBB;
      text-transform: uppercase;
    }
  }

  &--Like,
  &--Dislike {
    &--Selected {
      .Reaction__Button {
        color: #8134FF;
      }
    }
  }
}
.tooltip-container {
  position: relative;
}
.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  opacity: 0.8;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  width: 200px;
  text-align: center;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.AvatarPanel {
  position: absolute;
  left: 140%;
  top: -65px;
  transform: translateX(-50%);
  z-index: 99;
  background-color: #f2f2f2;
  padding: 15px;
  overflow: hidden; 
  margin-top: 65px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
  &__Header {
    display: flex;
    &__UserData{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      padding-top: 5px;
      h1 {
        font-size: 20px;
      }
      &__Detail{
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        margin-bottom: 20px;
      }
    }
  }
  &__Content {
    display: flex;
    flex-direction: column;
  }
  &__Body {
    white-space: nowrap;
    margin-left: 5px;
    margin-top: 5px;
  }

  &__Body p {
    margin-right: 15px;
    font-size: 15px;
  }

  &__Tags {
    background: #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    font-size: 5px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .TagV2 {
    margin-top: 2px;
    font-size: 10px;
    padding: 7px;
    padding-left:10px;
    padding-right:10px;
    margin: 3px;
  }
}
.AvatarPanel::before {
  content: "";
  position: absolute;
  left: 52px;
  top: -65px;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
}
.LoadingIcon {
  position: absolute;
  left: 52px;
  top: -65px;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #f2f2f2;
  padding: 20px;
  width: 104px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 140px;
  box-shadow: 0 0px 10px rgb(0 0 0 / 0.2);
}
</style>
