<template>
  <div class="ImageSelector">
    <div class="ImageSelector--Image" :style="{'background-image':'url(\''+image+'\')'}">
      <div class="ImageSelector--Uploader">
        <activitySVG v-if="!image || image.length == 0" :name="'plus-icon'"></activitySVG>
        <input type="file" :id="'file-'+_uid" v-on:change="uploadPhoto()">
        <span v-if="image?.length > 0">
          <i class="fas fa-cloud-upload-alt"></i>
        </span>
      </div>
    </div>
    <div class="ImageSelector--Info">
      <h1>{{title}}</h1>
      <p>{{text}}</p>
      <slot></slot>
    </div>
  </div>
 </template>
  
  <script>
    import { getCurrentInstance } from 'vue';
    import activitySVG from '~/components/ActivitySVG/activitySVG.vue'
    import PhotoUploader from '@/components/PhotoUploader.vue';
    import store from '@/store'
    export default {
      name: 'ImageSelector',
      components:{
        activitySVG,
        PhotoUploader
      },
      props:{
        modelValue: String,
        title: String,
        text: String,
        publicLogo: Boolean
      },
      watch:{
        image(newVal){
            this.$emit('update:modelValue', newVal)
        },
        modelValue(newVal){
            this.image = newVal
        }
      },
      data(){
        return{
          image: '',
          _uid:getCurrentInstance().uid
        }
      },
      methods:{
        uploadPhoto(){
          const files = document.getElementById('file-'+this._uid).files;
          if (files == null || files.length == 0) return
          var file = null;
          if (files != undefined) this.file = files[0];
          const payload = {
            file: this.file,
            publicLogo: this.publicLogo
          };
          store.dispatch('uploadFile', payload)
          .then((data) => {
            this.image = data.path
          })
        }
      }
    }
  </script>
  
  <style scoped lang="scss">
    .ImageSelector{
      display: flex;
      align-items: center;
      width: fit-content;
      margin-bottom: 20px;

      &--Info{
        display: flex;
        flex-direction: column;
        h1{
          font-weight: normal;
          font-size: 18px;
          color: #3E3E3E;
        }
        p{
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #747474;
          max-width: 220px;
        }
        select{
          background: #FFFFFF;
          border: 1px solid #E1E1E1;
          filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
          font-size: 15px;
          letter-spacing: 0.01em;
          color: #3E3E3E;
          outline: none;
          padding: 8px;
        }
      }
      &--Uploader{
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        font-family: 'Roboto condensed';
        &:hover{
          background: rgba(245, 245, 245, 0.5);
          transition: 0.5s;
        }
        span {
          font-size: 13px;
          position: absolute;
          top: 3px;
          right: 3px;
          background: rgb(150, 200, 0);
          color: white;
          cursor: pointer;
          padding: 4px;
          border-radius: 50%;
        }
      }
      &--Image{
        position: relative;
        width: 100px;
        min-width: 100px;
        height: 100px;
        background: #F5F5F5;
        border: 1px solid #D3D3D3;
        margin-right: 15px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
      input{
        height: calc(100% + 100px);
        top: -50%;
        position: absolute;
        width: 100%;
        left: 0;
        cursor: pointer;
        opacity: 0;
        z-index:1;
      }
    }
  </style>  