import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import moment from 'moment-timezone';
import mitt from 'mitt'
import App from './App.vue'
import '@/assets/css/common.scss'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import mixin from '@/mixins';
import {filters} from '@/filters'
import jQueryui from '@/assets/scripts/jquery-ui-1.13.0/jquery-ui.min.js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Antd from 'ant-design-vue';
import  VueSelect  from 'vue-select'
import 'vue-select/dist/vue-select.css' 
/*import 'ant-design-vue/dist/reset.css';*/

Bugsnag.start({
    apiKey: '78f3c8e849801fda5f4951d2ae89a720',
    plugins: [new BugsnagPluginVue()]
})
const bugsnagVue: any = Bugsnag.getPlugin('vue')

 $.extend(jQueryui);

const emitter = mitt()

const app = createApp( {
  extends:App,
  mixins:[mixin]
} )
  .use( router )
  .use( store )
  .use( bugsnagVue )
  .use( i18n )
    .use(VueCookieNext)
    .use(Antd)
  .component("v-select", VueSelect)
  .provide('emitter', emitter)
router.isReady( ).then( ( ) => { app.mount( '#app' ) } )

app.config.globalProperties.$filters = filters


declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
  $t: typeof i18n;
  }
}

export default app;
